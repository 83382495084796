import React, {
	useCallback,
	useContext,
	useMemo,
	useRef,
	useEffect,
	type ReactNode,
	type FC,
	type MutableRefObject,
	type ContextType,
	useLayoutEffect,
} from 'react';
import { Context } from '../context.tsx';
import { bindActions } from '../store/bind-actions.tsx';
import { StoreRegistry } from '../store/registry.tsx';
import type {
	Action,
	ActionThunk,
	GenericContainerComponent,
	OverrideContainerComponent,
	Store,
	StoreConfig,
} from '../types.tsx';
import shallowEqual from '../utils/shallow-equal.tsx';

// eslint-disable-next-line @typescript-eslint/no-empty-function -- ERROR TO BE FIXED
const noop = () => () => {};

type Options<TState, TContainerProps> = {
	/**
	 * @deprecated Do not use this. If you must, ensure that the associated container has getIsConcurrentSafe resolve to `true`. See https://hello.atlassian.net/wiki/spaces/JDP/pages/4802027965/ADR+react-sweet-state+RSS+concurrent+mode+compatibility
	 */
	onInit?: () => Action<TState, TContainerProps>;
	/**
	 * @deprecated Do not use this. If you must, ensure that the associated container has getIsConcurrentSafe resolve to `true`. See https://hello.atlassian.net/wiki/spaces/JDP/pages/4802027965/ADR+react-sweet-state+RSS+concurrent+mode+compatibility
	 */
	onUpdate?: () => Action<TState, TContainerProps>;
	onCleanup?: () => Action<TState, TContainerProps>;
	displayName?: string;
	/**
	 * This makes onInit and onUpdate concurrent safe. However - we will incur an additional render when the container props update compared to before. See https://hello.atlassian.net/wiki/spaces/JDP/pages/4802027965/ADR+react-sweet-state+RSS+concurrent+mode+compatibility
	 */
	getIsConcurrentSafe?: () => boolean; // We are using a function here to make it easier to feature gate this flag
};

export function createContainer<TContainerProps = unknown>(options?: {
	displayName?: string;
	getIsConcurrentSafe?: () => boolean; // We are using a function here to make it easier to feature gate this flag
}): GenericContainerComponent<TContainerProps>;

export function createContainer<
	TState,
	TActions extends Record<string, ActionThunk<TState, TActions>>,
	TContainerProps = unknown,
>(
	store: Store<TState, TActions>,
	options?: Options<TState, TContainerProps>,
): OverrideContainerComponent<TContainerProps>;

export function createContainer<
	TState,
	TActions extends Record<string, ActionThunk<TState, TActions>>,
	TContainerProps = unknown,
>(
	StoreOrOptions: Store<TState, TActions> | Options<TState, TContainerProps> = {},
	{
		onInit = noop,
		onUpdate = noop,
		onCleanup = noop,
		displayName = '',
		getIsConcurrentSafe,
	}: Options<TState, TContainerProps> = {},
): FC<FunctionContainerProps> {
	if ('key' in StoreOrOptions) {
		const Store = StoreOrOptions;
		const dn = displayName || `Container(${Store.key.split('__')[0]})`;

		return createFunctionContainer({
			displayName: dn,
			// compat fields
			override: {
				Store,
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment -- ERROR TO BE FIXED
				// @ts-ignore Object.assign expects Object as params
				// but logical expression can return false
				// Ignoring TS to retain original source
				// eslint-disable-next-line prefer-object-spread -- ERROR TO BE FIXED
				handlers: Object.assign(
					{},
					onInit !== noop && { onInit: () => onInit() },
					onCleanup !== noop && { onDestroy: () => onCleanup() },
					// TODO: on next major pass through next/prev props args
					onUpdate !== noop && { onContainerUpdate: () => onUpdate() },
				),
			},
			getIsConcurrentSafe,
		});
	}

	return createFunctionContainer(StoreOrOptions);
}

function useRegistry(
	scope: string,
	isGlobal: boolean,
	{ globalRegistry }: { globalRegistry: StoreRegistry },
) {
	return useMemo(() => {
		const isLocal = !scope && !isGlobal;
		return isLocal ? new StoreRegistry('__local__') : globalRegistry;
	}, [scope, isGlobal, globalRegistry]);
}

type Overrides<
	TState,
	TActions extends Record<string, ActionThunk<TState, TActions>>,
	TContainerProps = unknown,
> = {
	Store: Store<TState, TActions>;
	handlers: {
		/**
		 * @deprecated Do not use this. If you must, ensure that the associated container has getIsConcurrentSafe resolve to `true`. See https://hello.atlassian.net/wiki/spaces/JDP/pages/4802027965/ADR+react-sweet-state+RSS+concurrent+mode+compatibility
		 */
		onInit?: () => Action<TState, TContainerProps>;
		onDestroy?: () => Action<TState, TContainerProps>;
		/**
		 * @deprecated Do not use this. If you must, ensure that the associated container has getIsConcurrentSafe resolve to `true`. See https://hello.atlassian.net/wiki/spaces/JDP/pages/4802027965/ADR+react-sweet-state+RSS+concurrent+mode+compatibility
		 */
		onContainerUpdate?: () => Action<TState, TContainerProps>;
	};
};

type PropsRef = MutableRefObject<{
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- ERROR TO BE FIXED
	prev: Record<string, any> | null;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- ERROR TO BE FIXED
	next: Record<string, any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- ERROR TO BE FIXED
	sub: Record<string, any>;
}>;

function useContainedStore<
	TState,
	TActions extends Record<string, ActionThunk<TState, TActions>>,
	TContainerProps,
>(
	scope: string,
	registry: StoreRegistry,
	propsRef: PropsRef,
	check: (store: Store<TState, TActions>) => boolean,
	override?: Overrides<TState, TActions, TContainerProps>,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- ERROR TO BE FIXED
): [Map<Store<TState, TActions>, any>, (store: Store<TState, TActions>) => any] {
	// Store contained scopes in a map, but throwing it away on scope change
	// PLEASE CONFIRM THESE DEPENDENCIES ARE CORRECT

	// eslint-disable-next-line react-hooks/exhaustive-deps, @typescript-eslint/no-explicit-any, react-hooks/exhaustive-deps -- PLEASE FIX THIS SUPPRESSION
	const containedStores = useMemo(() => new Map<Store<TState, TActions>, any>(), [scope]);

	const getContainedStore = useCallback(
		(Store: Store<TState, TActions>) => {
			let containedStore = containedStores.get(Store);
			// first time it gets called we add store to contained map bound
			// so we can provide props to actions (only triggered by children)
			if (!containedStore) {
				const isExisting = registry.hasStore(Store, scope);
				const config: StoreConfig = {
					props: () => propsRef.current.sub,
					// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- ERROR TO BE FIXED
					contained: check as StoreConfig['contained'],
				};
				const { storeState } = registry.getStore(Store, scope, config);
				const actions = bindActions(Store.actions, storeState, config);
				const handlers = bindActions(
					// eslint-disable-next-line prefer-object-spread -- ERROR TO BE FIXED
					Object.assign({}, Store.handlers, override?.handlers),
					storeState,
					config,
					actions,
				);
				containedStore = {
					storeState,
					actions,
					handlers,
					// eslint-disable-next-line @typescript-eslint/ban-ts-comment -- ERROR TO BE FIXED
					// @ts-ignore - TS2345 Argument of type '[]' is not assignable to parameter of type 'Parameters<TActions[string]>'.
					unsubscribe: undefined,
				};
				containedStores.set(Store, containedStore);
				// Signal store is contained and ready now, so by the time
				// consumers subscribe we already have updated the store (if needed).
				// Also if override maintain legacy behaviour, triggered on every mount
				// eslint-disable-next-line @typescript-eslint/ban-ts-comment -- ERROR TO BE FIXED
				// @ts-ignore - TS2345 Argument of type '[]' is not assignable to parameter of type 'Parameters<TActions[string]>'.
				if (!isExisting || override) handlers.onInit?.();
			}
			return containedStore;
		},
		[containedStores, scope, registry, propsRef, check, override],
	);
	return [containedStores, getContainedStore];
}

function useApi<TState, TActions extends Record<string, ActionThunk<TState, TActions>>>(
	check: (store: Store<TState, TActions>) => boolean,
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- ERROR TO BE FIXED
	getContainedStore: (store: Store<TState, TActions>) => any,
	{ globalRegistry, retrieveStore }: ContextType<typeof Context>,
	isConcurrentSafe?: boolean,
) {
	// eslint-disable-next-line @typescript-eslint/no-explicit-any -- ERROR TO BE FIXED
	const retrieveRef = useRef<any>(
		isConcurrentSafe
			? (Store: Store<TState, TActions>) =>
					check(Store) ? getContainedStore(Store) : retrieveStore(Store)
			: undefined,
	);
	if (isConcurrentSafe) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		useLayoutEffect(() => {
			retrieveRef.current = (Store: Store<TState, TActions>) =>
				check(Store) ? getContainedStore(Store) : retrieveStore(Store);
		}, [check, getContainedStore, retrieveStore]);
	} else {
		retrieveRef.current = (Store: Store<TState, TActions>) =>
			check(Store) ? getContainedStore(Store) : retrieveStore(Store);
	}

	// This api is "frozen", as changing it will trigger re-render across all consumers
	// so we link retrieveStore dynamically and manually call notify() on scope change
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions -- ERROR TO BE FIXED
	return useMemo(
		() => ({
			globalRegistry,
			retrieveStore: (s: Store<TState, TActions>) => retrieveRef?.current?.(s),
		}),
		[globalRegistry],
	) as ContextType<typeof Context>;
}

type FunctionContainerProps = {
	children: ReactNode;
	scope: string;
	isGlobal: boolean;
};

function createFunctionContainer<
	TState,
	TActions extends Record<string, ActionThunk<TState, TActions>>,
	TContainerProps = unknown,
>({
	displayName,
	override,
	getIsConcurrentSafe,
}: {
	displayName?: string;
	override?: Overrides<TState, TActions, TContainerProps>;
	getIsConcurrentSafe?: () => boolean; // We are using a function here to make it easier to feature gate this flag
} = {}): FC<FunctionContainerProps> {
	const check = (store: Store<TState, TActions>) =>
		override ? store === override.Store : store.containedBy === FunctionContainer;

	function FunctionContainer(props: FunctionContainerProps) {
		const isConcurrentSafe = getIsConcurrentSafe?.() ?? false;
		const { children, ...restProps } = props;
		const { scope, isGlobal, ...subProps } = restProps;
		const ctx = useContext(Context);
		const registry = useRegistry(scope, isGlobal, ctx);

		// Store props in a ref to avoid re-binding actions when they change and re-rendering all
		// consumers unnecessarily. The update is handled by an effect on the component instead
		const propsRef: PropsRef = useRef({ prev: null, next: restProps, sub: subProps });
		if (!isConcurrentSafe) {
			propsRef.current = {
				prev: propsRef.current.next,
				next: restProps,
				sub: subProps, // TODO remove on next major
			};
		}

		const [containedStores, getContainedStore] = useContainedStore(
			scope,
			registry,
			propsRef,
			check,
			override,
		);

		// Use a stable object as is passed as value to context Provider
		const api = useApi(check, getContainedStore, ctx, isConcurrentSafe);

		if (isConcurrentSafe) {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useLayoutEffect(() => {
				propsRef.current = {
					prev: propsRef.current.next,
					next: restProps,
					sub: subProps, // TODO remove on next major
				};

				if (!shallowEqual(propsRef.current.next, propsRef.current.prev)) {
					containedStores.forEach(({ handlers }) => {
						handlers.onContainerUpdate?.(propsRef.current.next, propsRef.current.prev);
					});
				}
			}, [containedStores, restProps, subProps]);

			// This listens for custom props change, and so we trigger container update actions
			// before the re-render gets to consumers (hence why side effect on render).
			// We do not use React hooks because num of restProps might change and react will throws
		} else if (!shallowEqual(propsRef.current.next, propsRef.current.prev)) {
			containedStores.forEach(({ handlers }) => {
				handlers.onContainerUpdate?.(propsRef.current.next, propsRef.current.prev);
			});
		}

		// Every time we add/remove a contained store, we ensure we are subscribed to the updates
		// as an effect to properly handle strict mode
		useEffect(() => {
			containedStores.forEach((containedStore) => {
				if (!containedStore.unsubscribe) {
					const unsub = containedStore.storeState.subscribe(() =>
						containedStore.handlers.onUpdate?.(),
					);
					// eslint-disable-next-line no-param-reassign
					containedStore.unsubscribe = () => {
						unsub();
						// eslint-disable-next-line no-param-reassign
						containedStore.unsubscribe = undefined;
					};
				}
			});
		}, [containedStores, containedStores.size]);

		if (isConcurrentSafe) {
			// eslint-disable-next-line react-hooks/rules-of-hooks
			useLayoutEffect(() => {
				if (override && !containedStores.size && (scope || isGlobal)) {
					getContainedStore(override.Store);
				}
			}, [containedStores.size, getContainedStore, isGlobal, scope]);

			// We support renderding "bootstrap" containers without children with override API
			// so in this case we call getCS to initialize the store globally asap
		} else if (override && !containedStores.size && (scope || isGlobal)) {
			getContainedStore(override.Store);
		}

		// This listens for scope change or component unmount, to notify all consumers
		// so all work is done on cleanup
		useEffect(
			() => () => {
				containedStores.forEach(({ storeState, handlers, unsubscribe }, Store) => {
					// Detatch container from subscription
					unsubscribe?.();
					// Trigger a forced update on all subscribers as we opted out from context
					// Some might have already re-rendered naturally, but we "force update" all anyway.
					// This is sub-optimal as if there are other containers with the same
					// old scope id we will re-render those too, but still better than context
					storeState.notify();
					// Given unsubscription is handled by useSyncExternalStore, we have no control on when
					// React decides to do it. So we schedule on next tick to run last
					Promise.resolve().then(() => {
						if (
							!storeState.listeners().size &&
							// ensure registry has not already created a new store with same scope
							storeState === registry.getStore(Store, scope, null)?.storeState
						) {
							handlers.onDestroy?.();
							// We only delete scoped stores, as global shall persist and local are auto-deleted
							if (!isGlobal) registry.deleteStore(Store, scope);
						}
					});
				});
				// no need to reset containedStores as the map is already bound to scope
			},
			[registry, scope, containedStores, isGlobal],
		);

		return <Context.Provider value={api}>{children}</Context.Provider>;
	}

	FunctionContainer.displayName = displayName || 'Container';

	return FunctionContainer;
}

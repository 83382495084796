import type { DefaultFeatureFlagValue, FeatureFlagValue } from '../../common/types.tsx';
import getAllFeatureFlags, { _haveValuesArrived } from '../get-all-feature-flags/index.tsx';
import { parseFeatureFlagValue } from '../parse-feature-flag-value/index.tsx';

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export type { FeatureFlagValue } from '../../common/types';

type WindowFlagVars = {
	// created in: src/entry-fs
	__SSR_IS_FAST_META_ENABLED__: boolean | undefined;
	// created in: src/entry-fs
	__SSR_SYNC_FF__:
		| undefined
		| (<T extends FeatureFlagValue>(gateName: string, defaultValue: T) => T);
};

/**
 * @deprecated Use `expVal` or `fg` instead - see https://hello.atlassian.net/wiki/spaces/JFP/pages/3382881038/Jira+Playbook+Statsig for more details.
 *
 * @param defaultValue boolean 'true' value is not allowed go/ff-default
 */
export function getFeatureFlagValue<T extends FeatureFlagValue>(
	featureFlagName: string,
	defaultValue: T extends DefaultFeatureFlagValue ? T : never,
): T {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const globalVar = (typeof window !== 'undefined'
		? window
		: globalThis) as unknown as WindowFlagVars;

	if (
		typeof _haveValuesArrived !== 'undefined' &&
		!_haveValuesArrived() &&
		globalVar.__SSR_IS_FAST_META_ENABLED__ &&
		typeof globalVar.__SSR_SYNC_FF__ === 'function'
	) {
		const flagInSSR = globalVar.__SSR_SYNC_FF__(featureFlagName, defaultValue);
		return flagInSSR;
	}
	return parseFeatureFlagValue(getAllFeatureFlags(), featureFlagName, defaultValue);
}

/**
 * @param defaultValue No other values allowed other than false go/ff-default
 */
export const ff = (featureFlagName: string, defaultValue: false = false): boolean => {
	const flagValue = getFeatureFlagValue(featureFlagName, defaultValue);

	const isValid = typeof flagValue === 'boolean';

	return isValid ? flagValue : defaultValue;
};

/**
 * @deprecated Use `expVal` instead - see https://hello.atlassian.net/wiki/spaces/JFP/pages/3382881038/Jira+Playbook+Statsig for more details.
 */
export const getMultivariateFeatureFlag = <T extends string>(
	featureFlagName: string,
	defaultValue: T,
	possibleValues: Readonly<T[]>,
): T => {
	const flagValue = getFeatureFlagValue<T>(
		featureFlagName,
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		defaultValue as T extends DefaultFeatureFlagValue ? T : never,
	);

	const isValid = typeof flagValue === 'string' && possibleValues.includes(flagValue);

	return isValid ? flagValue : defaultValue;
};

import matchRouteOrderIndependent from '@atlassian/match-route';
import type { Query, Route, Routes } from '../../types.tsx';
import unSafeMutateRoute from '../unsafe-mutate-route.tsx';
import { matchRouteCache } from './utils.tsx';

const matchRoute = (routes: Routes, pathname: string, queryParams: Query = {}, basePath = '') => {
	const cachedMatch = matchRouteCache.get<Route>(pathname, queryParams, basePath);
	if (cachedMatch && routes.includes(cachedMatch.route)) return cachedMatch;
	const matchedRoute = matchRouteOrderIndependent<Route>(routes, pathname, queryParams, basePath);
	if (!matchedRoute) return null;
	unSafeMutateRoute(matchedRoute.route);
	matchRouteCache.set(pathname, queryParams, basePath, matchedRoute);

	return matchedRoute;
};

export default matchRoute;

import { type Action, type Location, parsePath } from 'history';
import { di } from 'react-magnetic-di';
import log from '@atlassian/jira-common-util-logging/src/log.tsx';
import killswitch from '@atlassian/jira-killswitch/src/index.tsx';
// eslint-disable-next-line jira/restricted/@atlassian/react-sweet-state
import {
	createActionsHook,
	createContainer,
	createHook,
	createStore,
	defaultRegistry,
	batch,
} from '@atlassian/react-sweet-state';
import {
	DEFAULT_ACTION,
	DEFAULT_HISTORY,
	DEFAULT_MATCH,
	DEFAULT_ROUTE,
} from '../../common/constants.tsx';
import type { Query, Route, JiraSpaRoute } from '../../common/types.tsx';
import { generateLocationFromPath } from '../../common/utils/generate-location/index.tsx';
import { generatePath as generatePathUsingPathParams } from '../../common/utils/generate-path/index.tsx';
import { isServerEnvironment } from '../../common/utils/is-server-environment/index.tsx';
import { warmupMatchRouteCache } from '../../common/utils/match-route/index.tsx';
import { findRouterContext } from '../../common/utils/router-context/index.tsx';
import type { AllRouterActions, ContainerProps, EntireRouterState, RouterState } from './types.tsx';
import {
	getRelativePath,
	isExternalAbsolutePath,
	updateQueryParams,
	getRelativeURLFromLocation,
	shouldReload,
} from './utils/index.tsx';

export const INITIAL_STATE: EntireRouterState = {
	action: DEFAULT_ACTION,
	basePath: '',
	location: DEFAULT_HISTORY.location,
	history: DEFAULT_HISTORY,
	match: DEFAULT_MATCH,
	onPrefetch: undefined,
	query: {},
	route: DEFAULT_ROUTE as JiraSpaRoute,
	routes: [],
	unlisten: null,
	plugins: [],
	isLazyRoutes: false,
	hasPerformedLazyTransition: false,
};

const actions: AllRouterActions = {
	/**
	 * Bootstraps the store with initial data.
	 *
	 */
	bootstrapStore:
		(props) =>
		({ setState, dispatch }) => {
			const {
				basePath = '',
				history,
				initialRoute,
				onPrefetch,
				routes,
				plugins,
				isLazyRoutes,
			} = props;
			const routerContext = findRouterContext<JiraSpaRoute>(
				initialRoute ? [initialRoute] : routes,
				{
					location: history.location,
					basePath,
				},
			);

			setState({
				...routerContext,
				basePath,
				history,
				onPrefetch,
				routes,
				location: history.location,
				action: history.action,
				plugins,
				isLazyRoutes,
				hasPerformedLazyTransition: false,
			});

			if (!isServerEnvironment()) {
				dispatch(actions.listen());
			}
		},

	/**
	 * Starts listening to browser history and sets the unlisten function in state.
	 * Will request route resources on route change.
	 *
	 */
	listen:
		() =>
		({ getState, setState }) => {
			const { history, unlisten } = getState();
			if (unlisten) unlisten();

			type LocationUpateV4 = [Location, Action];
			type LocationUpateV5 = [{ location: Location; action: Action }];
			const stopListening = history.listen((...update: LocationUpateV4 | LocationUpateV5) => {
				const location = update.length === 2 ? update[0] : update[0].location;
				const action = update.length === 2 ? update[1] : update[0].action;

				const {
					plugins,
					routes,
					basePath,
					match: currentMatch,
					route: currentRoute,
					query: currentQuery,
					isLazyRoutes,
					hasPerformedLazyTransition,
				} = getState();

				const nextContext = findRouterContext<JiraSpaRoute>(routes, {
					location,
					basePath,
				});

				const prevContext = {
					route: currentRoute,
					match: currentMatch,
					query: currentQuery,
				};

				const shouldReloadByPlugin = new Map(
					plugins.map((plugin) => [
						plugin.id,
						shouldReload({
							context: nextContext,
							prevContext,
							pluginId: plugin.id,
						}),
					]),
				);

				const updateState = () => {
					plugins.forEach((p) => {
						if (shouldReloadByPlugin.get(p.id)) {
							p.beforeRouteLoad?.({
								context: prevContext,
								nextContext,
							});
						}
					});
					// If:
					// * we are in the "lazy routes experiment",
					// * we haven't killswitched this logger
					// * and it's the first transition since loading the route list
					// - log it as a "successful transition"
					// This will let us work out a proper(-ish) success metric. Killswitch is just in case the
					// log volume proves excessive
					let nextHasPerformedLazyTransition = hasPerformedLazyTransition;
					if (isLazyRoutes && !hasPerformedLazyTransition) {
						if (!killswitch('lazyroute_success_logger')) {
							// Since we're doing a transition, we don't need to await as this _should_ complete
							// (and we don't want to block)
							log.safeInfoWithoutCustomerData('lazy_routes.transition', 'success', {
								route: nextContext.route.name,
							});
						}
						nextHasPerformedLazyTransition = true;
					}

					setState({
						...nextContext,
						location,
						action,
						hasPerformedLazyTransition: nextHasPerformedLazyTransition,
					});

					plugins.forEach((p) => {
						if (shouldReloadByPlugin.get(p.id)) {
							p.routeLoad?.({ context: nextContext, prevContext });
						}
					});
				};

				updateState();
			});

			setState({
				unlisten: stopListening,
			});
		},

	push:
		(path, state) =>
		({ getState }) => {
			di(window);
			const { history, basePath } = getState();
			if (isExternalAbsolutePath(path)) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.location.assign(path as string);
			} else {
				history.push(getRelativePath(path, basePath), state);
			}
		},

	pushTo:
		(route, attributes = {}) =>
		({ getState }) => {
			const { history, basePath } = getState();
			const location = generateLocationFromPath(route.path, {
				...attributes,
				basePath,
			});
			warmupMatchRouteCache(route, location.pathname, attributes.query, basePath);
			history.push(location as any, attributes?.state);
		},

	replace:
		(path, state) =>
		({ getState }) => {
			di(window);
			const { history, basePath } = getState();
			if (isExternalAbsolutePath(path)) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.location.replace(path as string);
			} else {
				history.replace(getRelativePath(path, basePath) as any, state);
			}
		},

	replaceTo:
		(route, attributes = {}) =>
		({ getState }) => {
			const { history, basePath } = getState();
			const location = generateLocationFromPath(route.path, {
				...attributes,
				basePath,
			});
			warmupMatchRouteCache(route, location.pathname, attributes.query, basePath);
			history.replace(location as any, attributes?.state);
		},

	goBack:
		() =>
		({ getState }) => {
			const { history } = getState();

			// history@4 uses goBack(), history@5 uses back()
			if ('goBack' in history) {
				history.goBack();
				/* TODO history@5 support
			} else if ('back' in history) {
				history.back(); */
			} else {
				throw new Error('History does not support goBack');
			}
		},

	goForward:
		() =>
		({ getState }) => {
			const { history } = getState();

			// history@4 uses goForward(), history@5 uses forward()
			if ('goForward' in history) {
				history.goForward();
				/* TODO history@5 support
			} else if ('forward' in history) {
				history.forward(); */
			} else {
				throw new Error('History does not support goForward');
			}
		},

	registerBlock:
		(blocker) =>
		({ getState }) => {
			const { history } = getState();

			return history.block(blocker);
		},

	getContext:
		() =>
		({ getState }) => {
			const { query, route, match } = getState();

			return { query, route, match };
		},

	getBasePath:
		() =>
		({ getState }) => {
			const { basePath } = getState();

			return basePath;
		},

	updateQueryParam:
		(params, updateType = 'push') =>
		({ getState }) => {
			const { query: existingQueryParams, history, location } = getState();
			const updatedQueryParams = { ...existingQueryParams, ...params };
			// remove undefined keys
			Object.keys(updatedQueryParams).forEach(
				(key) => updatedQueryParams[key] === undefined && delete updatedQueryParams[key],
			);
			const existingPath = updateQueryParams(location, existingQueryParams);
			const updatedPath = updateQueryParams(location, updatedQueryParams as Query);

			if (updatedPath !== existingPath) {
				history[updateType](updatedPath);
			}
		},

	updatePathParam:
		(params, updateType = 'push') =>
		({ getState }) => {
			const {
				history,
				location,
				route: { path },
				match: { params: existingPathParams },
				basePath,
			} = getState();
			const pathWithBasePath = basePath + path;
			const updatedPathParams = { ...existingPathParams, ...params };
			const updatedPath = generatePathUsingPathParams(pathWithBasePath, updatedPathParams);
			const updatedLocation = { ...location, pathname: updatedPath };

			const existingRelativePath = getRelativeURLFromLocation(location);
			const updatedRelativePath = getRelativeURLFromLocation(updatedLocation);

			if (updatedRelativePath !== existingRelativePath) {
				history[updateType](updatedRelativePath);
			}
		},
	loadPlugins:
		() =>
		({ getState }) => {
			const { plugins, match, query, route } = getState();

			plugins.forEach((p) => p.routeLoad?.({ context: { match, query, route } }));
		},
	prefetchRoute:
		(path, nextContext) =>
		({ getState }) => {
			const { plugins, routes, basePath, onPrefetch } = getState();
			const { route, match, query } = getRouterState();

			if (!nextContext && !isExternalAbsolutePath(path)) {
				const location = parsePath(getRelativePath(path, basePath) as any);
				// eslint-disable-next-line no-param-reassign
				nextContext = findRouterContext(routes, { location, basePath });
			}

			if (nextContext == null) return;
			const nextLocationContext = nextContext;

			batch(() => {
				plugins.forEach((p) =>
					p.routePrefetch?.({
						context: { route, match, query },
						nextContext: nextLocationContext,
					}),
				);
				if (onPrefetch) onPrefetch(nextLocationContext);
			});
		},
	setRoutes:
		(routes: Route[]) =>
		({ getState, setState }) => {
			const { routes: currentRoutes, history } = getState();
			const currentRoutesByName = Object.fromEntries(currentRoutes.map((r) => [r.name, r]));
			// To ensure that history is preserved, we need to find any current routes
			// in the new route map, and ensure that object identity for that route
			// is preserved in the new route map
			for (let i = 0; i < routes.length; i++) {
				const route = routes[i];
				if (Object.hasOwn(currentRoutesByName, route.name)) {
					// eslint-disable-next-line no-param-reassign
					routes[i] = currentRoutesByName[route.name];
				}
			}
			// Refresh info in history for transition blocking
			history.refreshRoutes && history.refreshRoutes(routes);
			setState({ routes });
		},
};

type State = EntireRouterState;

type Actions = AllRouterActions;

export const RouterStore = createStore<State, Actions>({
	initialState: INITIAL_STATE,
	actions,
	name: 'router',
});

export const RouterContainer = createContainer<State, Actions, ContainerProps>(RouterStore, {
	displayName: 'RouterContainer',
	onInit:
		() =>
		({ dispatch }, props) => {
			dispatch(actions.bootstrapStore(props));
			!isServerEnvironment() && dispatch(actions.loadPlugins());
		},
	onCleanup: () => (state) => {
		if (process.env.NODE_ENV === 'development') {
			// eslint-disable-next-line no-console
			console.warn(
				'Warning: react-resource-router has been unmounted! Was this intentional? Resources will be refetched when the router is mounted again.',
			);
		}
		if (!isServerEnvironment()) {
			const { unlisten } = state.getState();
			unlisten && unlisten();
		}
	},
});

export const useRouterStore = createHook<EntireRouterState, AllRouterActions>(RouterStore);

export const useRouterStoreActions = createActionsHook<EntireRouterState, AllRouterActions>(
	RouterStore,
);

/**
 * Utility to create custom hooks without re-rendering on route change
 */
export function createRouterSelector<T, U = void>(selector: (state: RouterState, props: U) => T) {
	const useHook = createHook<EntireRouterState, AllRouterActions, T, U>(RouterStore, { selector });

	return function useRouterSelector(...args: U extends undefined ? [] : [U]): T {
		// @ts-expect-error TS2345
		return useHook(...args)[0];
	};
}

export const getRouterStore = () =>
	// @ts-ignore calling `getStore` without providing a scopeId
	defaultRegistry.getStore<EntireRouterState, AllRouterActions>(RouterStore);

// @ts-ignore accessing private store property
export const getRouterState = () => getRouterStore().storeState.getState();

export const useIsActiveRoute = (routeOrRoutes: Set<string> | string) => {
	const selector = createRouterSelector(({ route: currentRoute }) => {
		if (typeof routeOrRoutes === 'string') {
			return currentRoute.name === routeOrRoutes;
		}
		return routeOrRoutes.has(currentRoute.name);
	});

	return selector();
};

export const addRouteListener = (listener: (state: RouterState) => void) => {
	const store = getRouterStore();
	const unsubscriber = store.storeState.subscribe(listener);

	return () => unsubscriber();
};

import memoizeOne from 'memoize-one';
import FeatureFlagClient from '@atlaskit/feature-flag-client';
import { getAllFeatureFlags } from '@atlassian/jira-feature-flagging-using-meta';
import { getAnalyticsWebClientPromise } from '@atlassian/jira-product-analytics-web-client-async';

const getAnalyticsClientInstance = () =>
	getAnalyticsWebClientPromise().then((analyticsClient) => analyticsClient.getInstance());

const createFeatureFlagClient = (): FeatureFlagClient => {
	const client = new FeatureFlagClient({
		analyticsHandler: {
			sendOperationalEvent: async (event) => {
				if (!__SERVER__) {
					getAnalyticsClientInstance().then((analyticsClient) => {
						// SSR doesn't have an analytics client
						if (!analyticsClient) {
							return;
						}

						// customize event data here if needed
						analyticsClient.sendOperationalEvent(event);
					});
				}
			},
		},
		flags: undefined,
	});

	return client;
};

const featureFlagClient = createFeatureFlagClient();

const getFeatureFlags = () => {
	const flags = getAllFeatureFlags();

	if (Object.keys(flags).length === 0) {
		if (__SERVER__) {
			const err = new Error(
				"Feature flags are not available yet. The feature flag client can't be initialized",
			);

			// print out the stack trace for SSR, so we can have better understanding on
			// which module is using the client too early.
			// in SSR, we use bunyan to replace the console.error, so we can use the standard
			// log format for apps.
			// eslint-disable-next-line no-console
			console.error({ err });
		} else {
			// eslint-disable-next-line no-console
			console.error(
				"Feature flags are not available yet. The feature flag client can't be initialized",
			);
		}
	}

	return flags;
};

const configureFeatureFlagClient = () => {
	const flags = getFeatureFlags();
	// @ts-expect-error - TS2345 - Argument of type 'import("/buildeng/bamboo-agent-home/xml-data/build-dir/JF-TSMIG123-APPLY/src/packages/platform/utils/feature-flagging-using-meta/src/common/types").Flags' is not assignable to parameter of type 'import("/buildeng/bamboo-agent-home/xml-data/build-dir/JF-TSMIG123-APPLY/node_modules/@atlaskit/feature-flag-client/dist/types/types").Flags'.
	featureFlagClient.setFlags(flags);

	if (!__SERVER__) {
		featureFlagClient.setIsAutomaticExposuresEnabled(true);
	}

	return featureFlagClient;
};

type FFClientProvider = () => FeatureFlagClient;

// NOTE: `memoizeOne` is doing unnecessary checks, what we need in this file is
// a simple local variable implementation, if local variable doesn't exist then
// create it.
const getMemoizedFeatureFlagClient: FFClientProvider = memoizeOne(configureFeatureFlagClient);

type WindowFlagVars = {
	isReorderEarlyScriptsEnabled: boolean | undefined;
	invalidateFeatureFlagsClient_DO_NOT_USE: boolean | undefined;
};

let builtFeatureFlagClient: FFClientProvider;
export const getFeatureFlagClient: FFClientProvider = () => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const globalVar = (typeof window !== 'undefined'
		? window
		: globalThis) as unknown as WindowFlagVars;

	if (globalVar.isReorderEarlyScriptsEnabled === true) {
		if (
			typeof builtFeatureFlagClient === 'undefined' ||
			globalVar.invalidateFeatureFlagsClient_DO_NOT_USE === true
		) {
			globalVar.invalidateFeatureFlagsClient_DO_NOT_USE = false;
			builtFeatureFlagClient = memoizeOne(configureFeatureFlagClient);
		}
		return builtFeatureFlagClient();
	}

	return getMemoizedFeatureFlagClient();
};

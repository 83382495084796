interface Scheduler {
	postTask<T>(
		callback: () => T,
		options?: { priority: 'user-blocking' | 'user-visible' | 'background' },
	): Promise<T>;
}
interface Global {
	scheduler?: Scheduler;
}
declare let globalThis: Global;

export const polyfilledSchedulerPostTask = <T,>(
	callback: () => T,
	priority: 'user-blocking' | 'user-visible' | 'background',
): Promise<T> => {
	if ('scheduler' in globalThis) {
		// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
		const retVal = globalThis.scheduler!.postTask(callback, { priority });
		return retVal;
	}
	return new Promise((resolve) => {
		setTimeout(() => {
			resolve(callback());
		});
	});
};

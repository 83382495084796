import FeatureGates from '@atlaskit/feature-gate-js-client';
import { addFeatureFlagAccessed } from '@atlaskit/react-ufo/feature-flags-accessed';
import { UNSAFE_initIfNeeded } from '@atlassian/jira-feature-gates-unsafe-init/src/index.tsx';
import { captureException } from '@atlassian/jira-capture-exception/src/index.tsx';

type WindowFlagVars = {
	isReorderEarlyScriptsEnabled: boolean | undefined;
	allowStatsigInitialisation_DO_NOT_USE: boolean | undefined;
	__SSR_IS_FAST_META_ENABLED__: boolean | undefined;
	__SSR_SYNC_FG__: undefined | ((gateName: string) => boolean);
};

class ErrorPointingUp extends Error {
	constructor(message: string) {
		super(message);
		// Capture the stack without the error construction
		if (this.stack) {
			const stackLines = this.stack.split('\n');
			// Remove the error creation line and preserve caller stack
			// 1 - checkIfGateExists
			// 2 - featureGate
			// 3 - fg
			this.stack = [...stackLines.slice(3)].join('\n');
		}
	}
}

const checkedGates = new Set<string>();
const checkIfGateExists = (gateName: string) => {
	// perform early exist to minimize performance impact
	if (checkedGates.has(gateName)) {
		return;
	}
	checkedGates.add(gateName);
	if (
		FeatureGates.checkGate('jfp_magma_jira-report-incorrect-gates', { fireGateExposure: false })
	) {
		if (!FeatureGates.isGateExists(gateName)) {
			const error = new ErrorPointingUp(`Feature gate ${gateName} does not exist`);
			captureException('FeatureGates.isGateExists', error);
			// eslint-disable-next-line no-console
			console.error(
				`Feature gate ${gateName} does not exist in StatSig. It was archived or hasn't been created yet`,
			);
		}
	}
};

let shouldPerformGateExistenceCheck = true;

/**
 * Disables gate existence check for the duration of the function call.
 * @deprecated reserved for special cases only
 */
export const withoutFeatureExistenceCheck = <T,>(fn: () => T): T => {
	shouldPerformGateExistenceCheck = false;
	const result = fn();
	shouldPerformGateExistenceCheck = true;
	return result;
};

const featureGate: typeof FeatureGates.checkGate = (gateName) => {
	// Handle vendorless scripts use of gates
	UNSAFE_initIfNeeded();

	const value = FeatureGates.checkGate(gateName);
	addFeatureFlagAccessed(gateName, value);

	if (shouldPerformGateExistenceCheck) {
		checkIfGateExists(gateName);
	}

	return value;
};

export const fg: typeof FeatureGates.checkGate = (gateName) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const globalVar = (typeof window !== 'undefined'
		? window
		: globalThis) as unknown as WindowFlagVars;

	/**
	 * This is the codepath before _statsigValues is initilaised.
	 * This function is defined within jira/src/entry-fs
	 */
	if (
		globalVar.__SSR_IS_FAST_META_ENABLED__ === true &&
		typeof globalVar.__SSR_SYNC_FG__ === 'function' &&
		!('_statsigValues' in globalVar)
	) {
		return globalVar.__SSR_SYNC_FG__(gateName);
	}

	if (globalVar.isReorderEarlyScriptsEnabled) {
		if (globalVar.allowStatsigInitialisation_DO_NOT_USE) {
			return featureGate(gateName);
		}

		// StatSig is not allowed to initialise yet, returning false
		return false;
	}

	return featureGate(gateName);
};

const featureGateNoExposure: typeof FeatureGates.checkGate = (gateName) => {
	// Handle vendorless scripts use of gates
	UNSAFE_initIfNeeded();

	const value = FeatureGates.checkGate(gateName, { fireGateExposure: false });

	return value;
};

/**
 * Feature gate without firing exposures
 *
 * Using this function will avoid sending exposures into observability tools including UFO and Statsig.
 *
 * ---
 *
 * ***You should use the regular `fg()` function in almost all cases.***
 *
 * Please do not use `UNSAFE__fgNoExposure` without discussing with #devinfra-app-framework beforehand.
 *
 * @param gateName feature gate key
 * @returns boolean result of feature gate evaluation
 */
export const UNSAFE__fgNoExposure: typeof FeatureGates.checkGate = (gateName) => {
	// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
	const globalVar = (typeof window !== 'undefined'
		? window
		: globalThis) as unknown as WindowFlagVars;

	if (globalVar.isReorderEarlyScriptsEnabled) {
		if (globalVar.allowStatsigInitialisation_DO_NOT_USE) {
			return featureGateNoExposure(gateName);
		}

		// StatSig is not allowed to initialise yet, returning false
		return false;
	}

	return featureGateNoExposure(gateName);
};

/* eslint-disable jira/filename-case */

import React, { type PropsWithChildren } from 'react';
import type { History, Plugin, Route, RouterContext, Routes } from './common/types.tsx';
import { RouterContainer } from './controllers/router-store/index.tsx';

export type RouterProps = PropsWithChildren<{
	basePath?: string;
	history: History;
	isGlobal?: boolean;
	initialRoute?: Route;
	isLazyRoutes?: boolean;
	onPrefetch?: (routerContext: RouterContext) => void;
	plugins?: Plugin[];
	routes: Routes;
}>;

export const Router = ({
	basePath,
	children,
	history,
	initialRoute,
	isGlobal = true,
	plugins,
	onPrefetch,
	routes,
	isLazyRoutes = false,
}: RouterProps) => (
	<RouterContainer
		basePath={basePath}
		history={history}
		initialRoute={initialRoute}
		isGlobal={isGlobal}
		onPrefetch={onPrefetch}
		plugins={plugins ?? []}
		routes={routes}
		isLazyRoutes={isLazyRoutes}
	>
		{children}
	</RouterContainer>
);

import noop from 'lodash/noop';
import AnalyticsClient, {
	CompressionRule,
	envType,
	originType,
	tenantType,
	userType,
} from '@atlassiansox/analytics-web-client';
import { buildCompressionFunction, canCompress } from '@atlassian/exposure-events-compressor';
import {
	UI_EVENT_TYPE,
	TRACK_EVENT_TYPE,
	SCREEN_EVENT_TYPE,
	OPERATIONAL_EVENT_TYPE,
} from '@atlassian/jira-product-analytics-bridge';
import {
	type UnknownEvent,
	type AnalyticsWebClient,
	type OriginTracingHandlerMapping,
	type InitAttrs,
	type UIViewedAttributes,
	type AnalyticsEvent,
	isAnalyticsEvent,
} from '@atlassian/jira-product-analytics-web-client-types';
/**
 * Serves as the primary interface for analytics operations within a web context. This class is responsible
 * for the initialization of the analytics framework, optimizing UI responsiveness by managing blocking durations,
 * and facilitating the capture and transmission of different analytics events. It ensures seamless integration
 * of user, product, and environmental attributes into analytics data, aiding in comprehensive data analysis and
 * insights generation. This class abstracts complex analytics operations, providing a simplified interface for
 * event tracking and analytics management.
 */
export default class DefaultAnalyticsWebClient implements AnalyticsWebClient {
	analyticsClient!: AnalyticsClient;

	// The time it took to initialise the client
	initTime!: number;

	// Number of times an event was triggered
	eventCount!: number;

	// Duration we're blocking the UI thread with operations
	blockingDuration!: number;

	resetBlockingDuration() {
		this.eventCount = 0;
		this.blockingDuration = 0;
	}

	init({
		tenantId,
		userId,
		subProduct,
		embeddedProduct,
		product,
		environment,
		locale,
		callback,
		shouldTriggerUIViewed = true,
		solutions,
		theme,
		orgId,
		workspaceId,
	}: InitAttrs) {
		const start = performance.now();
		this.analyticsClient = new AnalyticsClient(
			{
				env: environment || envType.DEV,
				product,

				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				version: window.BUILD_KEY || 'unknown',
				origin: originType.WEB,
				locale,
			},
			{
				useStargate: true,
				delayQueueCompressors: [new CompressionRule(canCompress, buildCompressionFunction(50))],
				...(process.env.PILLAR_LOCAL_DEVELOPMENT
					? // When running in the Pillar local dev loop, we need to ensure requests are over the right host
						{ apiHostProtocol: process.env.__PILLAR_ENABLE_HTTPS === 'true' ? 'https' : 'http' }
					: {}),
			},
		);
		if (!this.analyticsClient) {
			return false;
		}
		if (tenantId) {
			this.analyticsClient.setTenantInfo(tenantType.CLOUD_ID, tenantId);
		}
		if (userId) {
			this.analyticsClient.setUserInfo(userType.ATLASSIAN_ACCOUNT, userId);
		}
		if (subProduct) {
			this.analyticsClient.setSubproduct(subProduct);
		}
		if (orgId) {
			this.analyticsClient.setOrgInfo(orgId);
		}
		if (workspaceId) {
			this.analyticsClient.setWorkspaceInfo(workspaceId);
		}
		this.clearUIViewedAttributes();
		if (solutions || theme) {
			const OStheme =
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window && window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
					? 'dark'
					: 'light';
			this.setUIViewedAttributes({
				...(solutions && {
					solutions,
				}),
				...(theme && {
					theme,
					OStheme,
				}),
			});
		}
		if (embeddedProduct) {
			this.analyticsClient.setEmbeddedProduct(embeddedProduct);
		}
		if (shouldTriggerUIViewed) {
			const callbackFn = typeof callback === 'function' ? callback : noop;
			this.analyticsClient.startUIViewedEvent(callbackFn);
		}

		// Set init time
		this.initTime = performance.now() - start;
		return true;
	}

	sendEvent(event: AnalyticsEvent | UnknownEvent) {
		if (isAnalyticsEvent(event)) {
			const { type, payload } = event;
			const start = performance.now();
			switch (type) {
				case UI_EVENT_TYPE:
					this.analyticsClient.sendUIEvent(payload);
					break;
				case TRACK_EVENT_TYPE:
					this.analyticsClient.sendTrackEvent(payload);
					break;
				case OPERATIONAL_EVENT_TYPE:
					this.analyticsClient.sendOperationalEvent(payload);
					break;
				case SCREEN_EVENT_TYPE:
					this.analyticsClient.sendScreenEvent(payload);
					break;
				default:
					break;
			}

			// Increment the blocking duration
			this.blockingDuration += performance.now() - start;
			this.eventCount += 1;
		}
	}

	getInstance() {
		return this.analyticsClient;
	}

	setSubProduct(subProduct: string) {
		this.analyticsClient.setSubproduct(subProduct);
	}

	setUIViewedAttributes(attributes: UIViewedAttributes) {
		this.analyticsClient.setUIViewedAttributes({
			...this.analyticsClient.getUIViewedAttributes(),
			...attributes,
		});
	}

	setEmbeddedProduct(embeddedProduct: string) {
		this.analyticsClient.setEmbeddedProduct(embeddedProduct);
	}

	removeUIViewedAttribute(key: keyof UIViewedAttributes) {
		const { [key]: value, ...rest } = this.analyticsClient.getUIViewedAttributes();
		this.analyticsClient.setUIViewedAttributes({
			...rest,
		});
	}

	clearUIViewedAttributes() {
		this.analyticsClient.clearUIViewedAttributes();
	}

	clearEmbeddedProduct() {
		this.analyticsClient.clearEmbeddedProduct();
	}

	stopApdexEvent(payload: unknown) {
		this.analyticsClient.stopApdexEvent(payload);
	}

	setOriginTracingHandlers(originTracingHandlerMapping: OriginTracingHandlerMapping) {
		this.analyticsClient.setOriginTracingHandlers(originTracingHandlerMapping);
	}
}
